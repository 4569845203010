import React, {useState} from 'react';
import styled from 'styled-components';
import Carousel from 'react-slick';
import {Breakpoints} from '../../constants';
import BannerFirst from '../../../static/images/intro/ATTCINEMAS1.jpg';
import BannerSecond from '../../../static/images/intro/ATTCINEMAS2.jpg';
import HoneyQ from '../../../static/images/intro/HONEY Q.jpg';
import ACES from '../../../static/images/intro/ACES.jpg';
import SUSEE from '../../../static/images/intro/SUSEE.jpg';
import HoneyQ_Logo from '../../../static/images/intro/honeyq_logo.png';
import SUSEE_Logo from '../../../static/images/intro/susee_logo.png';
import ACES_Logo from '../../../static/images/intro/aces_logo.png';
import {SectionHeader} from '../Landing/SectionHeader';
import ACES_Logo2 from '../../../static/images/intro/Christie_RealLaser_Logo_RGB_HR_2018-10-05_500.png';
import ACES_Logo3 from '../../../static/images/intro/Dolby_Atmos.png';
import {Cross} from '@styled-icons/entypo';

const CINEMA_INTROS = [
  {
    screenImgUrl: HoneyQ,
    logoImgUrl: HoneyQ_Logo,
    title: 'HONEY Q 情人廳',
    context: '全台首創情人主題式影廳，提供給戀人們專屬隱密觀影空間體驗。',
    children: null,
  },
  {
    screenImgUrl: ACES,
    logoImgUrl: ACES_Logo,
    title: 'ACES 王牌廳',
    context: '台灣首座搭載 Dolby System 136 銀幕聲道喇叭高規格影廳。',
    children: (
      <div style={{minWidth: 300}}>
        <img src={ACES_Logo2} alt="aces logo 2" width={120} />
        <Cross style={{margin: '5px 0'}} size={40} color={'#aaa'}></Cross>
        <img src={ACES_Logo3} alt="aces logo 3" width={100} />
      </div>
    ),
  },
  {
    screenImgUrl: SUSEE,
    logoImgUrl: SUSEE_Logo,
    title: 'SUSEE 舒適廳',
    context:
      '全台最舒服影廳首選，給你如家一般放鬆愜意之感受，大大提升每排間距，絕對超越全台影廳排距規格，獨家打造一個舒適觀影的影廳。',
    children: null,
  },
];

function CinemaIntroItem(props) {
  const {intro, reverse} = props;
  return (
    <StyledCinemaIntroItem reverse={reverse} className="cinema-intro-item">
      <div className="cinema-image-wrapper">
        <img src={intro.screenImgUrl} alt="cinema" className="cinema" />
      </div>
      <div style={{flexBasis: '45px'}} />
      <div className="content">
        <img src={intro.logoImgUrl} alt="cinema-logo" className="cinema-logo" />
        {intro.children}
        <div className="title">{intro.title}</div>
        <div className="context">{intro.context}</div>
      </div>
    </StyledCinemaIntroItem>
  );
}

const StyledCinemaIntroItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({reverse}) => (reverse ? 'row-reverse' : 'reverse')};
  margin-bottom: 80px;
  & > .cinema-image-wrapper {
    flex: 1;
    padding-top: 30%;
    position: relative;
    & > img.cinema {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  & > .content {
    flex: 1;
    & > img.cinema-logo {
      width: 250px;
      height: 87px;
      object-fit: contain;
      margin-bottom: 20px;
    }
    & > .title {
      font-size: 18px;
      color: #000000;
    }
    & > .context {
      font-size: 13px;
      color: #000000;
    }
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    flex-direction: column;
  }
`;

const IntroPage = (props) => {
  const [dragging, setDragging] = useState(false);
  const CarouselSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    showArrow: false,
    autoPlay: true,
    beforeChange: () => {
      setDragging(true);
    },
    afterChange: () => {
      setDragging(false);
    },
  };

  return (
    <Wrapper>
      <Carousel {...CarouselSettings}>
        {[{imgUrl: BannerFirst}, {imgUrl: BannerSecond}].map((b, i) => (
          <HeroBannerSection
            onClick={async (e) => {
              if (dragging) {
                return;
              }
            }}
            key={i}
            image={b.imgUrl}
          />
        ))}
      </Carousel>
      <div className="content">
        <SectionHeader
          title="影城介紹"
          caption="introduce"
          style={{marginBottom: 30}}
        />
        <div className="introduction">
          {`
          ATT吸引力影城斥資超過二億打造，我們致力將全球最好的電影設備帶到大直，在視覺功能和整體效率方面領先於全台，全面引進嶄新設備與獨特技術，讓影迷享有耳目一新的觀影體驗及美好的新視覺饗宴，同時以全台首發引進的頂級系統影廳也將同步亮相，為的就是要帶給消費者最新最棒的新『視』界。\n
          大直ATT吸引力影城共推出14個影廳，近千個座位數，並主打全影廳無死角的黃金座位，讓你購票不必再糾結，其中包含3個最具話題性的設備影廳：
         【ACES 王牌廳】台灣首座4K 120幀高規格影廳，放膽挑戰全台影城放映規格，帶給您超乎想像的觀影視野。
         【HONEY Q 情人廳】全台首創情人主題式影廳，提供給戀人們專屬隱密觀影空間體驗。
         【SUSEE 舒適廳】全台最舒服影廳首選，給你如家一般放鬆愜意之感受，大大提升每排間距，絕對超越全台影廳排距規格，獨家打造一個舒適觀影的影廳。\n
          ※本影城為方便消費者，亦設有多項空間及設備：
          ●輪椅席(共24個席次)
          ●無障礙電梯
          ●無障礙廁所
          ●性別友善廁所
          ●哺集乳室
`}
        </div>
        <SectionHeader
          title="主題影廳"
          caption="cinema"
          style={{marginBottom: 30}}
        />
        {CINEMA_INTROS.map((intro, i) => (
          <CinemaIntroItem key={i} intro={intro} reverse={i % 2 !== 0} />
        ))}
      </div>
      <div className="cinema-info">
        <div className="context">
          <SectionHeader
            title="影城資訊"
            caption="information"
            style={{marginBottom: 30}}
          />
          <div className="info">
            {`
            服務專線     (02)7700-7040
            影城地址     台北市中山區敬業三路123號8樓 (大直ATT e Life )\n
            交通資訊\n
            捷運 請搭乘捷運文湖線於『劍南站』下車，步行約 7分鐘。
            公車 至「敬業三路」下車後，步行約 1 分鐘。(208、268、藍7、藍20區間車、棕20)
            公車 至「大直美堤花園三」下車後，步行約 2 分鐘。(33、42、42區間車、72、208直達車)
              `}
          </div>
        </div>

        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.613392917829!2d121.55327561526262!3d25.081089442555513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ad6cafbdc1c9%3A0x5764a8a860ae8496!2sATT%204%20Recharge!5e0!3m2!1szh-TW!2stw!4v1628057661337!5m2!1szh-TW!2stw"
            width="100%"
            height="100%"
            style={{border: 'none'}}
            loading="lazy"
          />
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .content {
    max-width: var(--contentMaxWith);
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    & > .introduction {
      font-size: 13px;
      color: #000000;
      white-space: pre-line;
      margin-bottom: 50px;
    }
  }
  & > .cinema-info {
    padding: 0 100px;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: row;
    & > .context {
      flex: 1;
      padding: 50px 0;
      & > .info {
        white-space: pre-line;
      }
    }
    & > .map-container {
      flex: 1;
    }
  }

  @media screen and (max-width: ${Breakpoints.md}px) {
    & > .content {
      padding: 50px 20px;
    }

    & > .cinema-info {
      padding: 0 20px;
      flex-direction: column;
    }
  }
`;

const HeroBannerSection = styled.section`
  padding: 80px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: ${({image}) => `url(${image})`};
  background-position: center;
  background-size: cover;
  background-color: orange;
  & > h2 {
    font-size: 32px;
    color: white;
  }
  & > p {
    font-size: 18px;
    color: #ccc;
  }

  @media screen and (max-width: ${Breakpoints.sm}px) {
    min-height: 180px;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    min-height: 300px;
  }
`;

export default IntroPage;
